/**
 * Модель контента страницы.
 *
 * @author Макарова Ольга <makarova.oa@dns-shop.ru>
 */

export class EventModel {
	/** @property Url изображения */
	public image?: string;

	/** @property Url превью изображения */
	public imagePreview?: string;

	/** @property Заголовок */
	public title?: string;

	/** @property Дата события */
	public date?: string;

	/** @property Url записи */
	public link?: string;

	/** @property Текст */
	public content?: string;
}
