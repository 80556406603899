import {
	default as _dayjs
	} from 'dayjs';
import 'dayjs/locale/ru';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';

_dayjs.extend(customParseFormat);
_dayjs.extend(localizedFormat);
_dayjs.extend(isBetween);
_dayjs.locale('ru');

export const dayjs = _dayjs;
export default _dayjs;
