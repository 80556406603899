/** Направления (отделы) */
export const API_DEPARTMENT          = '/api/v1/direction';
/** Города */
export const API_CITY                = '/api/v1/city';
/** Вакансии */
export const API_WORK                = '/api/v1/work';
/** Полезные материалы */
export const API_MATERIALS           = '/api/v1/material';
/** Отправка данных формы */
export const API_SEND_FORM           = '/dns-it/send-form/';
/** Получение данных цифрового оффера */
export const API_OFFER               = '/dns-it/get-offer/';
/** Принять оффер */
export const API_ACCEPT_OFFER        = '/dns-it/accept-offer/';
/** Отклонить оффер */
export const API_DECLINE_OFFER       = '/dns-it/cancel-offer/';
/** Отправка обратной связи по офферу */
export const API_OFFER_FEEDBACK      = '/dns-it/send-offer-feedback/';
/** Отправка рекомендательного письма */
export const API_SEND_REFERRAL_EMAIL = '/dns-it/send-referral-email/';
/** Отправка резюме */
export const API_SEND_RESUME         = '/api/v1/work/resume';
