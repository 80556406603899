import {defineStore} from 'pinia';
import {API_CITY, API_DEPARTMENT, API_MATERIALS, API_WORK} from '~/api-config';
import {ApiResponseBody} from '~/models/api-response';
import {City} from '~/models/city';
import {DnsTechState} from '~/models/dns-tech-state';
import {EventModel} from '~/models/event-model';
import {Job} from '~/models/job';
import {MaterialModel} from '~/models/material-model';
import {OfferModel} from '~/models/offer-model';
import dayjs from '~/helpers/dayjs';
import {DepartmentModel} from '~/models/department-model';

/** Стор для окружения приложения */
export const useDnsTech = defineStore('dns-tech', {
	state:   (): DnsTechState => {
		return {
			/** Url API */
			api: '',
			/** Url DNS */
			dnsSiteHost: '',
			/** Все события */
			events: [],
			/** Текущее событие */
			event: {},
			/** Все города */
			cities: [
				{
					id:    '',
					title: 'Все города',
				}
			],
			/** Вакансии */
			jobs: new Map<string, Job[]>(),
			/** Направления (отделы) */
			departments: [],
			/** Выбранное направление (отдел) */
			selectedDepartment: null,
			/** Пустой ответ на запрос вакансий */
			isJobResponseEmpty: false,
			/** Статьи */
			materials: [],
			/** Текущая статья */
			material: null,
			/** Данные цифрового оффера */
			offerData: null,
		}
	},
	actions: {
		/**
		 * Установка API
		 *
		 * @param value Значение для API
		 */
		setApi(value: string) {
			this.api = value;
		},

		/**
		 * Установка DNS сайта
		 *
		 * @param value Значение для API
		 */
		setDnsSiteHost(value: string) {
			this.dnsSiteHost = value;
		},

		/** Загрузка данных ленты событий. */
		async fetchEvents(payload?: { periodStart: dayjs.Dayjs, periodEnd: dayjs.Dayjs }) {
			const events: EventModel[] = [];
			const response: ApiResponseBody<{events: EventModel[]}> = await $fetch('/fake-api/events/data.json', {timeout: 5000});

			if (null !== response.data) {
				response.data.events.forEach((event) => {
					let condition = true;

					if (payload) {
						condition = dayjs(event.date, 'DD.MM.YYYY').isBetween(payload.periodStart, payload.periodEnd, 'days', '[]');
					}

					if (condition) {
						const eventModel        = new EventModel();
						eventModel.date         = event.date;
						eventModel.title        = event.title;
						eventModel.link         = event.link;
						eventModel.imagePreview = event.imagePreview;
						events.push(eventModel);
					}
				});

				this.events = events;
			}
		},

		/**
		 * Загрузка данных события.
		 *
		 * @param pageId Идентификатор события
		 */
		async fetchSingleEvent(pageId: string) {
			let event                                                = new EventModel();
			const apiData: ApiResponseBody<{ events: EventModel[] }> = await $fetch('fake-api/events/data.json');

			if (null !== apiData.data) {
				event      = apiData.data.events.filter(event => event.link == pageId)[0];
				this.event = event;
			}
		},

		/**
		 * Установка списка городов.
		 *
		 * @param apiData Данные api.
		 */
		setCities(apiData: City[]) {
			const cities = apiData.map((city) => {
				if (city.id !== city.title) {
					city.id = city.title
				}

				return city;
			});

			this.cities = [...this.cities, ...cities]
		},

		/**
		 * Установка списка вакансий.
		 *
		 * @param apiData Данные api.
		 */
		setJobs(apiData: Map<string, Job[]>) {
			this.jobs = apiData;
		},

		/**
		 * Установка флага, пустой ли ответ на запрос вакансий.
		 *
		 * @param isEmpty Флаг.
		 */
		setJobResponseEmpty(isEmpty: boolean) {
			this.isJobResponseEmpty = isEmpty;
		},

		/** Загрузка элементов списка вакансий. */
		async fetchJobs(data?: { inputValue: string, city: string, checkEmptyResponse: boolean, department: string }) {
			const apiData: ApiResponseBody = await $fetch(API_WORK);

			if (null !== apiData.data) {
				const result = new Map<string, Job[]>();

				Object.keys(apiData.data).forEach((index) => {
					apiData.data[index].forEach((job: Job) => {
						let condition = true;

						if (data) {
							const conditionCity       = (data.city ? (job.city.toLowerCase() === data.city.toLowerCase()) : true);
							const conditionSearch     = (job.title.toLowerCase().includes(data.inputValue.toLowerCase()));
							const conditionDepartment = ('' !== data.department ? (job.direction === data.department) : true);

							condition = (conditionCity && conditionSearch && conditionDepartment);
						}

						if (condition) {
							if (result.has(index)) {
								let searched = result.get(index);

								if (searched) {
									searched.push(job);
									result.set(index, searched);
								}
							}
							else {
								result.set(index, [job]);
							}
						}
					});
				});

				if (0 === [...result].length) {
					if (data?.checkEmptyResponse) {
						this.setJobResponseEmpty(true);
					}

					this.fetchJobs({inputValue: '', city: '', department: '', checkEmptyResponse: false});
				}
				else {
					if (data?.checkEmptyResponse) {
						this.setJobResponseEmpty(false);
					}
				}

				this.setJobs(result);
			}
		},

		/** Загрузка списка городов. */
		async fetchCities() {
			const apiData = await $fetch(API_CITY);

			this.setCities(apiData.data);
		},

		/** Загрузка данных блока направлений */
		async fetchDepartments() {
			const apiData: ApiResponseBody<DepartmentModel[]> = await $fetch(API_DEPARTMENT);

			if (null !== apiData.data) {
				this.departments = apiData.data;
			}
		},

		/** Загрузка всех материалов */
		async fetchMaterials() {
			const request: ApiResponseBody<MaterialModel[]> = await $fetch(API_MATERIALS);

			if (null !== request.data) {
				this.materials = request.data;
			}
		},

		/**
		 * Загрузка одного материала.
		 *
		 * @param {string} id Идентификатор материала
		 */
		async fetchOneMaterial(id: string) {
			const request: ApiResponseBody<MaterialModel> = await $fetch(`${API_MATERIALS}/${id}`);

			if (null !== request.data) {
				this.material = request.data;
			}
		},

		/** Загрузка данных оффера
		 *
		 * @param {string} guid Идентификатор оффера
		 */
		async fetchOfferData(guid: string) {
			const response: OfferModel = await $fetch('/offer-data', {
				params: {
					guid,
				}
			});

			if (null !== response) {
				this.offerData = response;
			}
		},

		/** Сохранить дату, выбранную кандидатом */
		setSelectedDate(value: string) {
			if (this.offerData) {
				this.offerData.chosenDate = value;
			}
		}
	},
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useDnsTech, import.meta.hot));
}
